import React from 'react';
import { connect } from 'react-redux';
import { IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import { authActions } from '$gbusiness/redux/auth';
import { ROUTES as GROUTES } from '$gbusiness/enums';
import { ROUTES } from '$business/enums';

import { configs } from '$configs';
import Menu from '$components/sideMenu';
import FactoryModel from '$fbusiness/models/factory';
import AppOutlet from '$product/_init/appOutlet';
import Receipt from '$components/receipt';
import DepartmentModel from '$fbusiness/models/department';
import { getFactoryLogo } from '$fbusiness/helpers/util';
import CurrentStateModel, { initialCurrentState } from '$fbusiness/models/currentState';
import StoreModel from '$fbusiness/models/store';

interface RouterProps {
  departments: Array<DepartmentModel>;
  isLoggedIn: boolean;
  currentRoute: string;
  onLogout: Function;
  userLevel?: number;
  store: StoreModel;
  factory: FactoryModel;
}

const Router: React.FC<RouterProps> = ({
  departments,
  factory,
  isLoggedIn,
  store,
  currentRoute,
  userLevel,
  onLogout,
}) => {
  const redirectRoute = isLoggedIn ? ROUTES.DEFAULT_APP_PATH : GROUTES.DEFAULT_PUBLIC_PATH;
  const isOrderRoute = currentRoute.includes(ROUTES.PATH.MENU) || currentRoute.includes(ROUTES.PATH.THANKYOU);

  const currentState: CurrentStateModel = {
    ...initialCurrentState,
    isLoggedIn,
    hidePrice: false,
    isWholesale: store?.settings?.wholesale === '1',
    settings: factory.settings,
  };

  return (
    <IonReactRouter basename={process.env.REACT_APP_PUBLIC_URL}>
      <IonSplitPane contentId="main" when={`(min-width: ${configs.display.splitPaneThreshold})`}>
        <Menu
          isOrderRoute={isOrderRoute}
          departments={departments}
          currentRoute={currentRoute}
          onLogout={onLogout}
          isLoggedIn={isLoggedIn}
          logo={getFactoryLogo(factory, true)}
        />
        <AppOutlet currentState={currentState} isLoggedIn={isLoggedIn} userLevel={userLevel} />
        <Receipt isHidden={!isOrderRoute} currentRoute={currentRoute} isLoggedIn={isLoggedIn} />
      </IonSplitPane>
      <Route path="/" render={() => <Redirect to={redirectRoute} />} exact />
    </IonReactRouter>
  );
};

const mapStateToProps = (state) => ({
  store: state.store.store,
  departments: state.department.departments,
  isLoggedIn: state.localStorage.accessToken !== '',
  currentRoute: state.routing.currentRoute,
  userLevel: state.currentUser?.userLevel,
  factory: state.factory.factory,
});

const mapDispatchToProps = {
  onLogout: authActions.logout,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Router);
