import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .header-title {
    margin: 0 !important;
    padding-top: ${SPACE.LARGE} !important;
  }
  .actions {
    button {
      border-radius: 0%;
      font-size: ${FONT.MEDIUM};
      text-transform: uppercase;
      padding: ${SPACE.LARGE};
    }
    .order-button {
      min-width: 200px;
    }
  }
`;
