import React from 'react';
import CheckIcon from '@mui/icons-material/Check';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { deriveRawToItem } from '$fbusiness/models/item';
import intl from '$intl';
import { Button } from '$gcomponents/primitives';
import { CSSTransition } from 'react-transition-group';
import Img from '$gcomponents/primitives/img';
import { timestamp } from '$gbusiness/helpers/date';

export const ITEMS_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.item.general,
    method: 'GET',
    deriveToModel: deriveRawToItem,
    defaultSortKey: 'updatedAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 50,
  },
  styles: {
    ...defaultStyles,
    responsive: true,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 320,
    rowHeight: 64,
    maxWidth: 1200,
    headerHeight: 64,
    cellPadding: ` 8px 4px`,
    isStripped: false,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const ITEMS_TABLE = (quantity, added, addCart, onChangeQty): Array<CellModel> => [
  {
    label: 'SCREEN.ITEMS.COLS.PHOTO',
    value: 'itemId',
    className: 'image',
    align: 'center',
    component: (row) => {
      return (
        row.image?.fileUrl && (
          <Img src={row.image.fileUrl} cache={timestamp(row.image.updatedAt)} height="64px" width="64px" />
        )
      );
    },
    alwaysShow: true,
    width: 100,
    minWidth: 64,
  },
  {
    label: 'SCREEN.ITEMS.COLS.NAME',
    value: 'name',
    className: 'no-label main',
    alwaysShow: true,
    width: 250,
  },
  {
    label: 'SCREEN.ITEMS.COLS.UPC',
    value: 'barcode',
    className: 'no-label',
    alwaysShow: true,
    width: 160,
  },
  {
    label: 'SCREEN.ITEMS.COLS.SKU',
    value: 'sku',
    className: 'no-label',
    alwaysShow: true,
    width: 160,
  },
  {
    label: 'SCREEN.ITEMS.COLS.RETAIL',
    value: 'price',
    align: 'center',
    alwaysShow: true,
    className: 'no-label',
    component: (row) => <>{intl('PRICE', { amount: row.retailPrice })}</>,
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.PRICE',
    value: 'price',
    align: 'center',
    alwaysShow: true,
    className: 'no-label',
    component: (row) => <>{intl('PRICE', { amount: row.wholesalePrice })}</>,
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.QUANTITY',
    value: 'quantity',
    className: 'action',
    component: (row, actions, i) => {
      return (
        <>
          <input
            type="number"
            className="qty"
            onFocus={(e) => e.target.select()}
            max={row.quantity}
            value={quantity[i] || ''}
            onChange={(e) => onChangeQty(e, i)}
          />
          {row.quantity !== null && (
            <span className="available">{`/${row.quantity === null ? '∞' : row.quantity}`}</span>
          )}
        </>
      );
    },
    // className: 'desktop',
    minWidth: 104,
    maxWidth: 104,
  },
  // {
  //   label: 'SCREEN.ITEMS.COLS.AVAILABLE',
  //   value: 'quantity',
  //   align: 'center',
  //   className: 'desktop',
  //   alwaysShow: true,
  //   width: 120,
  // },
  {
    label: '',
    value: '',
    className: 'action',
    align: 'center',
    component: (row, actions, i) => {
      return (
        <CSSTransition in={added === row.id} timeout={3000} classNames="cart-add" appear>
          <div>
            <Button
              className="add"
              fullWidth
              size="small"
              onClick={() => addCart(row, i)}
              disabled={!quantity[i] || (parseInt(quantity[i]) > row.quantity && row.quantity !== null)}>
              {intl('BUTTON.ADD')}
            </Button>
            <Button
              className="added"
              fullWidth
              size="small"
              endIcon={added && <CheckIcon />}
              onClick={() => addCart(row, i)}>
              {intl('BUTTON.ADDED')}
            </Button>
          </div>
        </CSSTransition>
      );
    },
    width: 180,
    minWidth: 92,
  },
];
