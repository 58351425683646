import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

import { ROUTES as GROUTES } from '$gbusiness/enums';
import { ROUTES } from '$business/enums';
import { canAccess } from '$gbusiness/helpers/util';

import PrivateRoute from './privateRoute';
import CurrentStateModel from '$fbusiness/models/currentState';

interface RouterProps {
  isLoggedIn: boolean;
  userLevel?: number;
  currentState: CurrentStateModel;
}

const AppOutlet: React.FC<RouterProps> = ({ currentState, isLoggedIn, userLevel }) => {
  return (
    <IonRouterOutlet id="main" animated={false}>
      {ROUTES.LIST.map((menu, i) => (
        <PrivateRoute
          key={i}
          currentState={currentState}
          exact={menu.exact || true}
          path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          isAccessible={canAccess(menu.accessLevel, userLevel)}
          feature={menu.feature}
        />
      ))}
      {ROUTES.PUBLIC_LIST.filter((m) => m.path !== GROUTES.PATH.LOGIN).map((menu, i) => (
        <Route
          key={i}
          path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          exact={menu.exact || true}
        />
      ))}

      <Route
        path={GROUTES.DEFAULT_PUBLIC_PATH}
        component={isLoggedIn ? undefined : GROUTES.DEFAULT_PUBLIC_ROUTE.component}
        render={isLoggedIn ? () => <Redirect to={ROUTES.DEFAULT_APP_PATH} exact /> : undefined}
        exact
      />
    </IonRouterOutlet>
  );
};

export default AppOutlet;
