import { Dispatch } from 'redux';

import { LOADING } from '$gbusiness/redux/loading/types';
import { LOAD_STORAGE } from '$gbusiness/redux/localStorage/types';
import { apiService, localStorage, theme } from '$gbusiness/services';
import { ROUTES } from '$business/enums';

import { INIT_SUCCESS, INIT_FAILURE } from './types';
import { configs } from '$configs';
import { SET_CUSER } from '$gbusiness/redux/currentUser/types';
import UserModel, { defaultUser, deriveRawToUser } from '$gbusiness/models/user';
import { handleApiFail } from '$gbusiness/services/api';

import { AppModel } from '../';
import { FETCH_ITEMS_SUCCESS } from '$fbusiness/redux/item/types';
import { deriveRawToItem } from '$fbusiness/models/item';
import { defaultCart } from '$fbusiness/models/cart';
import { deriveRawToDepartment } from '$fbusiness/models/department';
import { FETCH_DEPARTMENTS_SUCCESS } from '$fbusiness/redux/department/types';
import { FETCH_MY_FACTORY_SUCCESS, FETCH_STORE_SUCCESS } from '$fbusiness/redux/factory/types';
import { deriveRawToStore } from '$fbusiness/models/store';
import { deriveRawToFactory, initialFactory } from '$fbusiness/models/factory';
import { FETCH_CATEGORIES_SUCCESS } from '$fbusiness/redux/category/types';
import { deriveRawToCategory } from '$fbusiness/models/category';
import { setStorageObj } from '$gbusiness/services/local.storage';

function restoreTheme(localTheme) {
  theme.switchTheme(localTheme);
}

async function loadStorage(dispatch) {
  const storageData = await localStorage.getStorageData();
  const cart = storageData.cart ? JSON.parse(storageData.cart) : defaultCart;

  dispatch({
    type: LOAD_STORAGE,
    localStorage: {
      ...storageData,
      cart,
    },
  });
}

async function updateCart(dispatch, localStorage, items) {
  //const cart = syncCart(localStorage.cart, items);

  dispatch({
    type: LOAD_STORAGE,
    localStorage: {
      ...localStorage,
      //cart,
    },
  });
}

async function authToken(dispatch) {
  const response = await apiService.fetchApi({
    url: configs.api.token,
    param: {},
    method: 'POST',
  });

  if (!response || !response?.user?.id) {
    localStorage.clearAuth();
    return;
  }

  const user: UserModel = deriveRawToUser(response.user) || defaultUser;

  dispatch({
    type: SET_CUSER,
    user,
  });
}

async function getMyStore(dispatch, factory) {
  if (factory.items) return;

  const response = await apiService.fetchApi({
    url: configs.api.mystore,
    param: {},
    method: 'GET',
    // mockData,
  });

  if (!response || !response.data) {
    // handleApiFail(dispatch, INIT_FAILURE, response, 'ERROR.SERVER');
    return;
  }

  if (!response.data?.store) {
    handleApiFail(dispatch, INIT_FAILURE, response, 'ERROR.SERVER');
    return;
  }

  const store = deriveRawToStore(response.data.store);
  dispatch({
    type: FETCH_STORE_SUCCESS,
    store,
  });

  const myFactory = deriveRawToFactory(response.data.factory) || initialFactory;

  /* get features */
  for (const key in myFactory.settings) {
    const feature = myFactory.settings[key];
    if (typeof feature === 'boolean' && feature) {
      document.body.classList.add('body_' + key || '');
    }
  }
  if (myFactory.settings) {
    await setStorageObj('features', myFactory.settings);
  }

  const { categories, departments, items } = response.data;
  dispatch({
    type: FETCH_MY_FACTORY_SUCCESS,
    factory: deriveRawToFactory(response.data.factory),
  });
  dispatch({
    type: FETCH_DEPARTMENTS_SUCCESS,
    departments: (departments || []).map((d) => deriveRawToDepartment(d)),
  });
  dispatch({
    type: FETCH_CATEGORIES_SUCCESS,
    categories: (categories || []).map((d) => deriveRawToCategory(d)),
  });
  dispatch({
    type: FETCH_ITEMS_SUCCESS,
    items: (items || []).map((d) => deriveRawToItem(d)),
  });
}

export function fetchMyStore(): any {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    const { factory } = getState();
    await getMyStore(dispatch, factory);
  };
}

const shouldForcePublic = () => {
  const path = window.location.pathname;

  const thisRoute = ROUTES.PUBLIC_LIST.find((route) => path.includes(route.path));
  return thisRoute ? true : false;
};

export function loadApp(): any {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.INITIALIZING',
    });

    await loadStorage(dispatch);

    // Load Theme
    const { localStorage, factory } = getState();

    restoreTheme(localStorage.theme);

    if (!shouldForcePublic()) {
      await authToken(dispatch);
      await getMyStore(dispatch, factory);
    }

    const { item } = getState();

    await updateCart(dispatch, localStorage, item.items);

    dispatch({
      type: INIT_SUCCESS,
    });
  };
}
