import { ROUTES as GROUTES } from '$gbusiness/enums';
import * as FROUTES from '$fbusiness/enums/routes';
import RouteModel from '$gbusiness/models/route';
import PATH from './paths';

import { Restaurant } from '@mui/icons-material';
import { MenuScreen, CheckoutScreen, ThankyouScreen } from '$product/screens';

// IMPORTS FROM GBOX

export { PATH };

export const MENU_ROUTE = {
  // title: 'MENU.MENU',
  path: PATH.MENU,
  param: ':deptId/:categoryId',
  defaultId: '0/0',
  icon: Restaurant,
  component: MenuScreen,
};

export const CHECKOUT_ROUTE = {
  path: PATH.CHECKOUT,
  accessLevel: 2,
  component: CheckoutScreen,
};

export const THANKYOU_ROUTE = {
  path: PATH.THANKYOU,
  param: ':orderId',
  component: ThankyouScreen,
  exact: true,
};

export const LIST: Array<RouteModel> = [
  MENU_ROUTE,
  CHECKOUT_ROUTE,
  THANKYOU_ROUTE,
  FROUTES.ACCOUNT_ROUTE,
  FROUTES.HISTORY_ROUTE,
  FROUTES.INVOICES_ROUTE,
  FROUTES.RECEIVING_ROUTE,
  FROUTES.CARTS_ROUTE,
  FROUTES.PROFILE_ROUTE,
  FROUTES.USERS_ROUTE,
  FROUTES.USER_ROUTE,
];

export const ACCOUNT_LIST = FROUTES.ACCOUNT_LIST;

export const MENU_LIST: Array<RouteModel> = [FROUTES.ACCOUNT_ROUTE];
export const PUBLIC_LIST = [...GROUTES.PUBLIC_LIST];

export const defaultRoute = MENU_ROUTE;
export const DEFAULT_APP_PATH = defaultRoute.path + '/' + defaultRoute.defaultId;
