import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { ContentWrapper, ScreenTitle } from '$styles/general';
import { Modal, Table } from '$gcomponents/reusables';
import { cartActions } from '$fbusiness/redux/cart';
import Header from '$components/header';
import ItemModel, { initialItem } from '$fbusiness/models/item';

import { IonPageWrapper } from './styles';
import ModifierModalSection from './modifierModalSection';
import { ITEMS_TABLE_CONFIG, ITEMS_TABLE } from './table';
import intl from '$intl';
import CartModel from '$fbusiness/models/cart';
import { initActions } from '$business/redux/init';
import DepartmentModel from '$fbusiness/models/department';
import CurrentStateModel from '$fbusiness/models/currentState';
interface MenuScreenProps {
  match: any;
  currentState: CurrentStateModel;
  department: DepartmentModel;
  items: Array<ItemModel>;
  addToCart: Function;
  cart: CartModel;
  onHydrate;
  onDehydrate;
}

const MenuScreen: React.FC<MenuScreenProps> = ({
  department,
  currentState,
  match,
  items,
  addToCart,
  cart,
}) => {
  const { categoryId: catId, deptId: did } = match.params;
  const categoryId = parseInt(catId) || 0;
  const deptId = parseInt(did) || 0;
  // const { pointerIndex, products: cartItems } = cart;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [added, setAdded] = useState(0);
  const [quantity, setQuantity] = useState<Array<number>>(Array(items.length).fill(0));
  const [filter, setFilter] = useState<any>({
    query: '',
    categoryId,
    deptId,
  });

  const category = department ? (department?.categories || []).find((c) => c.id === filter.categoryId) : null;

  const addCart = (row, index) => {
    if (!row.modifierGroups || (!row.modifierGroups.length && false)) {
      addToCart({
        ...row,
        quantity: quantity[index],
      });
      setAdded(row.id);
      return;
    }
    setSelectedIndex(index);
  };

  const onCloseModal = () => {
    setSelectedIndex(-1);
  };
  const addCartFromModal = async (item) => {
    if (!item) return;
    addToCart(item);
    setAdded(item.id);
    onCloseModal();
  };

  useEffect(() => {
    setQuantity(Array(items.length).fill(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    setFilter({
      ...filter,
      categoryId,
      deptId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, deptId]);

  const onChangeQty = (e, i) => {
    const value: number = parseInt(e.target.value);
    let newQuantity: Array<number> = [...quantity];
    newQuantity[i] = value;
    setQuantity(newQuantity);
  };

  const onChangeQuery = (query) => {
    setFilter({
      ...filter,
      query,
    });
  };

  // Perform filter based on deptId, categoryId, and query string
  const filteredItem = items.filter((item) => {
    const { query, deptId, categoryId: catId } = filter;
    if (item.hide) return false;
    if (query) {
      if (item.name.toLowerCase().includes(query.toLowerCase())) return true;
      else return false;
    }
    if (deptId && item && !(item.deptIds || []).includes(deptId)) return false;
    if (catId && !(item.categoryIds || []).includes(catId)) return false;
    //if (query && !item.name.toLowerCase().includes(query.toLowerCase())) return false;
    return true;
  });

  // When Item is selected to add cart or popup modifier Modal
  const selectedItem: any =
    selectedIndex >= 0 ? { ...filteredItem[selectedIndex], quantity: quantity[selectedIndex] } : initialItem;
  // const defaultItem = !selectedItem
  //   ? undefined
  //   : pointerIndex < cartItems.length
  //   ? cartItems[pointerIndex]
  //   : itemToCartItem(selectedItem, quantity[selectedIndex]);

  const title = category?.name || department?.name || intl('SCREEN.ITEMS.TITLE');
  const styles = ITEMS_TABLE_CONFIG.styles;
  const itemsTable = ITEMS_TABLE(quantity, added, addCart, onChangeQty);
  const tableProps = {
    styles,
    TABLE: itemsTable,
  };
  return (
    <IonPageWrapper>
      <Header query={filter.query} onChangeQuery={onChangeQuery} />
      <IonContent>
        <ContentWrapper>
          <ScreenTitle center>{title}</ScreenTitle>
          <Table {...tableProps} data={filteredItem} />
        </ContentWrapper>
        <Modal show={selectedIndex >= 0} onClose={onCloseModal} titleText={selectedItem?.name} useCustom>
          <ModifierModalSection
            currentState={currentState}
            item={selectedItem}
            onClose={onCloseModal}
            onCartAdd={addCartFromModal}
          />
        </Modal>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state, ownProps) => ({
  cart: state.localStorage.cart,
  department: state.department.departments.find((d) => parseInt(ownProps.match.params.deptId) === d.id),
  items: state.item.items,
});

const mapDispatchToProps = {
  onHydrate: (params) => initActions.fetchMyStore(),
  addToCart: cartActions.addProduct,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MenuScreen));
