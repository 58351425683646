import React from 'react';

import QuantityEdit from '$gcomponents/primitives/quantityEdit';
import { Flex } from '$gstyles/wrapper';
import { currency } from '$gbusiness/helpers/util';

import { CartListWrapper } from './styles';
import intl from '$intl';

interface CartListProps {
  items?: Array<any>;
  onUpdateQuantity?: Function;
  includeHeader?: boolean;
  includePrice?: boolean;
}

const CartList: React.FC<CartListProps> = ({ items, onUpdateQuantity, includeHeader, includePrice }) => {
  if (!items?.length) return null;

  return (
    <CartListWrapper>
      {includeHeader && (
        <Flex className="cart-item header">
          <div>{intl('SCREEN.ORDERS.COLS.ITEMS')}</div>
          <div>{intl('SCREEN.ORDERS.COLS.QUANTITY')}</div>
        </Flex>
      )}
      {items &&
        items.map((item, i) => {
          const { modifiers, itemName, name, price, qty } = item;
          const instruction = item.instruction || item.note || '';
          return (
            <Flex key={i} className={`cart-item ${modifiers.length > 0 ? 'with-mod' : ''}`}>
              <div className="item-text">
                <div className="name">{itemName || name}</div>
                {modifiers.length > 0 && <div className="mod">({modifiers.map(i => i.name).join(', ')})</div>}
                {instruction && <div className="instruction">{`"${instruction}"`}</div>}
                {includePrice && (
                  <div className="price">{currency(price?.subtotalSum || price?.totalSum)}</div>
                )}
              </div>
              {!onUpdateQuantity ? (
                <div className="quantity">x {qty}</div>
              ) : (
                <div className="qty-item">
                  <QuantityEdit
                    value={qty}
                    onChange={q => onUpdateQuantity(i, q)}
                    inputSize="small"
                    name={`item_${i}`}
                  />
                </div>
              )}
            </Flex>
          );
        })}
    </CartListWrapper>
  );
};

export default CartList;
