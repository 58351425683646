import React from 'react';
import intl from '$intl';
import { OrderSummaryWrapper } from './styles';
import { currency } from '$gbusiness/helpers/util';

interface OrderSummaryProps {
  price: any;
  includeTitle?: boolean;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ price, includeTitle }) => {
  const { subtotal, total, tax, delivery } = price;
  return (
    <OrderSummaryWrapper>
      {includeTitle && <div className="title">{intl('SCREEN.CHECKOUT.ORDER_SUMMARY.TITLE')}</div>}
      <div className="box">
        <div className="line">
          <div className="label">{intl('SCREEN.CHECKOUT.ORDER_SUMMARY.ITEMS')}</div>
          <div className="value">{currency(subtotal)}</div>
        </div>
        <div className="line">
          <div className="label">{intl('SCREEN.CHECKOUT.ORDER_SUMMARY.SHIPPING')}</div>
          <div className="value">{currency(delivery)}</div>
        </div>
        <div className="line">
          <div className="label">{intl('SCREEN.CHECKOUT.ORDER_SUMMARY.TAX')}</div>
          <div className="value">{currency(tax)}</div>
        </div>
        <div className="line total">
          <div className="label">{intl('SCREEN.CHECKOUT.ORDER_SUMMARY.TOTAL')}</div>
          <div className="value">{currency(total)}</div>
        </div>
      </div>
    </OrderSummaryWrapper>
  );
};

export default OrderSummary;
