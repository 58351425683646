import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonFooter } from '@ionic/react';
import { Form, Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { screen } from '$gcomponents/hoc';
import Header from '$components/header';
import { userActions } from '$gbusiness/redux/user';
import { Div, SPACE } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import { Flex } from '$gstyles/wrapper';

import { IonPageWrapper } from './styles';
import { ADDRESS_FORM, PAYMENT_FORM, ADDITIONAL_FORM, getShippingForm } from './checkoutForm';
import intl from '$intl';
import { currency } from '$gbusiness/helpers/util';
import { orderActions } from '$fbusiness/redux/order';
import CartModel from '$fbusiness/models/cart';
import FactoryModel from '$fbusiness/models/factory';
import StoreModel from '$fbusiness/models/store';
import { cartActions } from '$fbusiness/redux/cart';
import { KEYS } from '$fbusiness/enums/options/orderStatus';
import { DATE_FORMATS } from '$gbusiness/enums';
import { getDeliveryDate } from '$fbusiness/helpers/util';
import PATH from '$business/enums/paths';
import { deriveCartToOrderItem } from '$fbusiness/models/orderInvoiceItem';

interface CheckoutScreenProps {
  history;
  store: StoreModel;
  factory: FactoryModel;
  cart: CartModel;
  isFinished: boolean;
  placeOrder: Function;
  resetCart: Function;
  updateShipping: Function;
}

const CheckoutScreen: React.FC<CheckoutScreenProps> = ({
  history,
  cart,
  store,
  factory,
  isFinished,
  placeOrder,
  resetCart,
  updateShipping,
}) => {
  const formRef = useRef<any>();
  const { shippings } = factory;
  const shippingOptions = shippings.map((s) => ({
    labelText: `${s.name} ${s.description || ''} (${currency(s.deliveryCost)}) - Deliver @ ${getDeliveryDate(
      s.days,
      DATE_FORMATS.DAY_WEEKDAY,
    )}`,
    value: s.id,
  }));
  const shippingRef = useRef<any>(shippings[0]);

  const initialValues = {
    formatted: store.formatted,
    paymentMethod: 'INVOICE',
    shippingOption: shippingRef.current.id,
  };

  useEffect(() => {
    updateShipping(shippingRef.current.deliveryCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippings]);

  useEffect(() => {
    onOrderSuccess();
  }, [isFinished]); // eslint-disable-line react-hooks/exhaustive-deps

  const onOrderSuccess = async () => {
    if (!isFinished) return;
    await resetCart();
    history.push(PATH.THANKYOU + '/0');
  };

  const onSubmit = (values) => {
    const { subtotal, tax, total, delivery: deliveryCost } = cart.price;
    const { shippingOption: shippingId, additionalMessage, formatted } = values;
    const newValues = {
      qty: cart.products.reduce((acc, p) => acc + Number(p.qty), 0),
      items: cart.products.map((p) => deriveCartToOrderItem(p)),
      paymentTypeId: 0,
      shippingId,
      storeId: store.id,
      subtotal,
      status: KEYS.PREPARING,
      deliveryCost,
      tax,
      total,
      deliveryAddress: formatted,
      deliveryDate: getDeliveryDate(shippingRef.current.days),
      note: additionalMessage,
    };
    placeOrder(0, newValues);
  };

  const onChangeShipping = (result) => {
    const { value } = result;
    const newValue = parseInt(value);
    shippingRef.current = shippings.find((s) => s.id === newValue);
    updateShipping(shippingRef.current?.deliveryCost);
  };

  const SHIPPING_FORM = getShippingForm({
    shippingOptions,
    onChange: onChangeShipping,
    shipping: shippingRef.current,
  });

  const validateForm = (values) =>
    input.validateError([...ADDRESS_FORM, ...PAYMENT_FORM, ...ADDITIONAL_FORM, ...SHIPPING_FORM], values);

  return (
    <IonPageWrapper>
      <Header title="SCREEN.CHECKOUT.TITLE" />
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <Div padding={SPACE.XLARGE} maxWidth="800px">
                <Form>
                  <FormSection title="SCREEN.CHECKOUT.GENERAL_TITLE" FORM={ADDRESS_FORM} formik={formik} />
                  <FormSection title="SCREEN.CHECKOUT.PAYMENT_TITLE" FORM={PAYMENT_FORM} formik={formik} />
                  <FormSection title="SCREEN.CHECKOUT.SHIPPING_TITLE" FORM={SHIPPING_FORM} formik={formik} />
                  <FormSection
                    title="SCREEN.CHECKOUT.ADDITIONAL_TITLE"
                    FORM={ADDITIONAL_FORM}
                    formik={formik}
                  />
                </Form>
                {/* <Flex className="actions" padding={SPACE.MEDIUM} justifyContent="space-around">
                  <Button size="large" variant="text" onClick={onCancel}>
                    {intl('BUTTON.CANCEL')}
                  </Button>
                  <Button size="large" className="" onClick={onSubmit}>
                    {intl('BUTTON.PLACE_ORDER')}
                  </Button>
                </Flex> */}
              </Div>
            </IonContent>

            <IonFooter className="actions">
              <Flex padding={SPACE.MEDIUM} justifyContent="center">
                <Button
                  size="large"
                  disabled={!formik.isValid}
                  className="third order-button"
                  onClick={formik.handleSubmit}>
                  {intl('BUTTON.PLACE_ORDER')}
                </Button>
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  store: state.factory.store,
  cart: state.localStorage.cart,
  isFinished: state.order.isFinished,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: () => userActions.fetchUser,
  placeOrder: orderActions.saveOrder,
  resetCart: cartActions.resetCart,
  updateShipping: cartActions.updateShipping,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CheckoutScreen));
