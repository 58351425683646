import React, { useEffect, useState } from 'react';
import intl from '$intl';
import ItemModel from '$fbusiness/models/item';

import { Button } from '$gcomponents/primitives';
import { Button as MuiButton, TextField } from '@mui/material';
import ModifierGroupSection from '../modifierGroupSection';
import CartItemModel from '$fbusiness/models/cartItem';

import { IonContentWrapper, IonFooterWrapper } from './styles';
import { Col, Row } from '$gstyles';
import { currency } from '$gbusiness/helpers/util';
import QuantityEdit from '$gcomponents/primitives/quantityEdit';
import CurrentStateModel from '$fbusiness/models/currentState';

interface ModifierModalSectionProps {
  item: ItemModel;
  currentState: CurrentStateModel;
  defaultItem?: CartItemModel;
  onClose: () => void;
  onCartAdd: Function;
}

const ModifierModalSection: React.FC<ModifierModalSectionProps> = ({
  item,
  onClose,
  onCartAdd,
  currentState,
  defaultItem,
}) => {
  const [selection, setSelection] = useState(defaultItem?.modifiers || []);
  const [quantity, setQuantity] = useState(defaultItem?.qty || 1);
  const [instruction, setInstruction] = useState(defaultItem?.instruction || '');

  useEffect(() => {
    if (item?.quantity) setQuantity(item.quantity);
  }, [item]);

  if (!item) return null;

  const { modifierGroups, retailPrice, wholesalePrice } = item;
  const price = currentState.isWholesale ? wholesalePrice : retailPrice;
  const handleSelectionChange = (newSelection) => {
    setSelection(newSelection);
  };

  const onInstructionChange = (e) => {
    if (!e?.target) return;
    setInstruction(e.target.value);
  };
  const handleSubmit = () => {
    onCartAdd({
      ...item,
      qty: quantity,
      instruction,
      modifiers: selection,
    });
  };

  const validateSelection = () => {
    const error = modifierGroups.find((mg, i) => {
      if (!mg.multiple || mg.minQuantity > 0) {
        if (!selection.find((m) => m.mgIndex === i)) return true;
      }
      return false;
    });
    return error;
  };

  return (
    <>
      <IonContentWrapper>
        <Row className="top-section">
          <div className="price">{`${intl('SCREEN.ITEMS.PRICE')}: ${currency(price)}`}</div>
        </Row>
        <div className="modifierSets">
          {modifierGroups.map((mg, i) => (
            <ModifierGroupSection
              key={i}
              index={i}
              modifierGroup={mg}
              selection={selection}
              onChange={handleSelectionChange}
            />
          ))}
        </div>
        <Row className="quantity">
          <Col gridSize={3}>
            <h4>{intl('SCREEN.ITEMS.QUANTITY')}</h4>
            <QuantityEdit
              value={quantity}
              onChange={(q) => setQuantity(q)}
              inputSize="medium"
              name="quantity"
            />
          </Col>
          <Col gridSize={9} className="instruction">
            <h4>{intl('INPUT.LABEL.SPECIAL_INSTRUCTION')}</h4>
            <TextField
              className="textarea"
              multiline
              variant="outlined"
              rows={2}
              value={instruction}
              onInput={onInstructionChange}
            />
          </Col>
        </Row>
      </IonContentWrapper>
      <IonFooterWrapper>
        <MuiButton className="outlined dark" onClick={onClose}>
          {intl('BUTTON.CANCEL')}
        </MuiButton>
        <Button onClick={handleSubmit} disabled={!!validateSelection()}>
          {intl('BUTTON.ADD_CART')}
        </Button>
      </IonFooterWrapper>
    </>
  );
};

export default ModifierModalSection;
