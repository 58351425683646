import styled from 'styled-components';
import { IonToolbar } from '@ionic/react';

import { ENUMS } from '$gstyles/enums';

export const Toolbar = styled(IonToolbar)`
  @media screen and (max-width: ${ENUMS.SPLIT_PANE_WIDTH}) {
    .can-go-back ion-menu-button {
      display: none;
    }
    ion-title {
      padding: 0;
      ion-searchbar {
        input.searchbar-input {
          padding-right: 40px;
        }
      }
    }
  }

  @media screen and (min-width: ${ENUMS.SPLIT_PANE_MIN_WIDTH}) {
    .can-go-back > ion-header ion-back-button {
      display: none !important;
    }
  }

  ion-searchbar {
    --box-shadow: initial;
    --background: #f5f5f5;
    --border-radius: 999px;
  }

  ion-button.receipt {
    min-width: 44px;
    height: 48px;
  }
`;
