import React from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { checkmarkOutline } from 'ionicons/icons';

import ModifierModel from '$fbusiness/models/modifier';
import { Wrapper, IconWrapper } from './styles';
import { currency } from '$gbusiness/helpers/util';
import { IonIcon } from '@ionic/react';
import { CartModifierModel } from '$fbusiness/models/cartItem';
import { deriveModifierPrice } from '$fbusiness/redux/cart/utils';
import ModifierGroupModel from '$fbusiness/models/modifierGroup';
import { dialog } from '$gcomponents/reusables';

interface ModifierSectionProps {
  index: number;
  mgIndex: number;
  mgQuantity: number;
  modifier: ModifierModel;
  modifierGroup: ModifierGroupModel;
  selection?: Array<CartModifierModel>;
  onChange: Function;
}

const setNumberOfChecks = (selection: CartModifierModel | undefined, maxChecks: number) => {
  if (!selection) return 1;
  const { qty } = selection;
  return qty >= maxChecks ? 0 : qty + 1;
};

const ModifierSection: React.FC<ModifierSectionProps> = ({
  index,
  mgIndex,
  mgQuantity,
  modifier,
  modifierGroup,
  selection: selections = [],
  onChange,
}) => {
  if (!modifier) return null;

  const { multiple, minQuantity, maxQuantity, name: label } = modifierGroup;
  const isOptional = multiple && minQuantity === 1 && maxQuantity;
  const isRadio = !multiple || isOptional;
  const { name: title, price, maxQuantity: maxChecks } = modifier;
  const selection = selections.find(s => s.index === index && s.mgIndex === mgIndex);
  const numChecks = selection?.qty || 0;

  const generateNewModifer = () => ({
    id: 0,
    mgIndex,
    index,
    mgId: modifierGroup.id,
    modifierId: modifier.id,
    name: title,
    label,
    qty: 1,
    price: Number(price),
  });

  const generateNewSelections = (obj, isNew = false, isRadio = false) => {
    if (isRadio && obj) return [...selections.filter(s => s.mgIndex !== mgIndex), obj];
    if (isRadio && !obj) return selections;
    if (!obj) return selections.filter(s => s.index !== index || s.mgIndex !== mgIndex);

    if (isNew) return [...selections, obj];
    return selections.map(s => {
      if (s.index !== index || s.mgIndex !== mgIndex) return s;
      return obj;
    });
  };

  const onClick = () => {
    // Not doing split yet
    if (!isRadio) {
      const quantity = setNumberOfChecks(selection, maxChecks || 1);
      // if (maxChecks && quantity > maxChecks) {
      //   alert('Maximum has reached');
      //   onChange(generateNewSelections(null));
      //   return;
      // }
      if (maxQuantity && quantity > 0 && mgQuantity >= maxQuantity) {
        dialog.alert({
          title: 'MESSAGE.MAX_SELECT_TITLE',
          message: 'MESSAGE.MAX_SELECT_MESSAGE',
          key: { maxQuantity, label },
        });
        onChange(generateNewSelections(null));
        return;
      }
      if (!quantity) onChange(generateNewSelections(null));
      else if (!selection) onChange(generateNewSelections(generateNewModifer(), true));
      else if (selection) onChange(generateNewSelections({ ...selection, qty: quantity }, false));
    } else {
      if (!selection || !selection.qty) {
        onChange(generateNewSelections(generateNewModifer(), true, !isOptional));
      } else {
        onChange(generateNewSelections(null, false, !isOptional));
      }
    }
  };

  const checks = Array(numChecks)
    .fill(1)
    .map((m, i) => (
      <IconWrapper margin={`${i * 2}px ${i * 7}px`} key={i} className="pointer" onClick={onClick}>
        <IonIcon icon={checkmarkOutline} />
      </IconWrapper>
    ));

  const calculatedPrice = deriveModifierPrice(selection);
  const CheckboxIcon = multiple ? CheckBoxOutlineBlankIcon : RadioButtonUncheckedIcon;

  return (
    <Wrapper className="flex modifier">
      <div className="check">
        <CheckboxIcon className="check-icon pointer" onClick={onClick} />
        {checks}
      </div>
      <div className="name">{title}</div>
      <div className="price">
        {calculatedPrice > 0 ? currency(calculatedPrice) : (price || 0) > 0 ? currency(price) : ''}
      </div>
    </Wrapper>
  );
};

export default ModifierSection;
