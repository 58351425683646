import React from 'react';
import ModifierGroupModel from '$fbusiness/models/modifierGroup';

import { Wrapper } from './styles';
import ModifierSection from '../modifierSection';
import { CartModifierModel } from '$fbusiness/models/cartItem';

interface ModifierGroupSectionProps {
  index: number;
  modifierGroup: ModifierGroupModel;
  selection: Array<CartModifierModel>;
  onChange: Function;
}

const ModifierGroupSection: React.FC<ModifierGroupSectionProps> = ({
  modifierGroup,
  index: mgIndex,
  selection = [],
  onChange,
}) => {
  if (!modifierGroup) return null;
  const { modifiers, name } = modifierGroup;
  const mgQuantity = selection.reduce((acc, m) => {
    if (m.mgIndex !== mgIndex) return acc;
    acc = acc + m.qty;
    return acc;
  }, 0);

  return (
    <Wrapper>
      <div className="description">{name}</div>
      {modifiers?.map((modifier, i) => (
        <ModifierSection
          key={i}
          index={i}
          mgQuantity={mgQuantity}
          mgIndex={mgIndex}
          modifierGroup={modifierGroup}
          modifier={modifier}
          selection={selection}
          onChange={onChange}
        />
      ))}
    </Wrapper>
  );
};

export default ModifierGroupSection;
