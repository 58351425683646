import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonHeader, IonToolbar, IonFooter } from '@ionic/react';
import { menuController } from '@ionic/core';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import { configs } from '$configs';

import { Wrapper } from './styles';
import { Div } from '$gstyles';
import { AppModel } from '$business/redux';
import { Button } from '$gcomponents/primitives';
import intl from '$intl';
import { Flex } from '$gstyles/wrapper';
import { cartActions } from '$fbusiness/redux/cart';
import MuiModal from '$gcomponents/reusables/modal/material';
import { TextField } from '@mui/material';
import FPATH from '$fbusiness/enums/paths';
import { ROUTES } from '$business/enums';
import OrderSummary from './orderSummary';
import CartList from './cartList';
import { dialog } from '$gcomponents/reusables';
import { canAccess } from '$gbusiness/helpers/util';

interface ReceiptProps extends RouteComponentProps {
  isLoggedIn: boolean;
  isHidden: boolean;
  currentRoute: string;
}

const Receipt: React.FC<ReceiptProps> = ({ currentRoute, isHidden, isLoggedIn, ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSaveCartModal, setShowSaveCartModal] = useState(false);
  const [cartName, setCartName] = useState('');
  const cart = useSelector((state: AppModel) => state.localStorage.cart);
  const user = useSelector((state: AppModel) => state.currentUser);
  const isFinished = useSelector((state: AppModel) => state.cart.isFinished);
  const isCheckout = currentRoute === ROUTES.PATH.CHECKOUT;
  const { products: items } = cart;
  const isThankyou = currentRoute.includes(ROUTES.PATH.THANKYOU);

  useEffect(() => {
    if (isFinished) {
      setShowSaveCartModal(false);
      dispatch(cartActions.cleanCart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  const onClearCart = () => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.DELETE_WARNING',
      cssClass: 'large',
      onPress: () => {
        dispatch(cartActions.resetCart());
      },
    });
  };
  const onSaveCart = () => {
    dispatch(cartActions.saveCart(items, 0, cartName));
  };
  const onCancel = () => {
    history.push(ROUTES.DEFAULT_APP_PATH);
  };
  const onCheckout = () => {
    history.push(ROUTES.PATH.CHECKOUT);
    menuController.close();
  };
  const onUpdateQuantity = (i, qty) => {
    if (qty === 0) {
      dispatch(cartActions.removeProduct(i));
      return;
    }
    dispatch(cartActions.changeProductQuantity(i, qty));
  };
  const navAccount = () => history.push(FPATH.ACCOUNT);
  const navCart = () => history.push(FPATH.CARTS);

  return (
    <Wrapper
      contentId="main"
      side="end"
      disabled={!isLoggedIn || isHidden}
      isProd={process.env.REACT_APP_ENV === 'prod'}
      {...rest}>
      {configs.display.layout !== 'FIXED_HEADER' && (
        <IonHeader>
          <IonToolbar>
            <Flex justifyContent="center">
              <PersonIcon />
              <span
                className="user-name pointer"
                onClick={navAccount}>{`${user.firstName} ${user.lastName}`}</span>
            </Flex>
          </IonToolbar>
        </IonHeader>
      )}

      {isThankyou ? (
        <IonContent />
      ) : (
        <>
          <IonContent class="outer-content">
            {items?.length > 0 && (
              <Flex justifyContent="center" className="item-count">
                {`${items.length} items`}
              </Flex>
            )}
            <CartList
              items={items}
              includePrice
              onUpdateQuantity={isCheckout ? undefined : onUpdateQuantity}
            />
            {isCheckout ? (
              <OrderSummary price={cart.pricing.po} includeTitle />
            ) : (
              items?.length > 0 && (
                <div className="center">
                  <Button
                    onClick={onClearCart}
                    className="clear-cart"
                    variant="text"
                    startIcon={<DeleteIcon />}>
                    {intl('BUTTON.CLEAR_CART')}
                  </Button>
                </div>
              )
            )}
          </IonContent>
          <IonFooter>
            {isCheckout ? (
              <Div>
                {/* <Button onClick={onPlaceOrder} fullWidth size="large">
                {intl('BUTTON.PLACE_ORDER')}
              </Button> */}
                <Button
                  onClick={onCancel}
                  className="cancel-button"
                  fullWidth
                  size="large"
                  variant="text"
                  color="secondary">
                  {intl('BUTTON.CANCEL')}
                </Button>
              </Div>
            ) : (
              <Div>
                {items && items.length > 0 ? (
                  <Button
                    onClick={() => setShowSaveCartModal(true)}
                    fullWidth
                    size="large"
                    variant="outlined">
                    {intl('BUTTON.SAVE_CART')}
                  </Button>
                ) : (
                  <Button onClick={navCart} fullWidth size="large" variant="outlined">
                    {intl('BUTTON.GO_TO_CART')}
                  </Button>
                )}
                <Button
                  onClick={onCheckout}
                  disabled={!items.length || !canAccess(2, user?.userLevel)}
                  fullWidth
                  size="large">
                  {intl('BUTTON.CHECKOUT')}
                </Button>
              </Div>
            )}
          </IonFooter>
          <MuiModal
            minWidth="400px"
            open={showSaveCartModal}
            title={intl('SCREEN.CART.SAVE_TITLE')}
            footer={<Button onClick={onSaveCart}>{intl('BUTTON.SUBMIT')}</Button>}
            onClose={() => setShowSaveCartModal(false)}>
            <TextField
              fullWidth
              label={intl('INPUT.LABEL.CART_NAME')}
              variant="outlined"
              onChange={(e) => setCartName(e.target.value.toString())}
              value={cartName}
            />
          </MuiModal>
        </>
      )}
    </Wrapper>
  );
};

export default withRouter(Receipt);
