import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { ROUTES } from '$gbusiness/enums';
import { Header } from '$gcomponents/widgets';
import CurrentStateModel from '$fbusiness/models/currentState';

export interface PrivateRouteProps extends RouteProps {
  currentState: CurrentStateModel;
  feature?: string;
  isAccessible: boolean;
  component: React.FC;
}

export const emptyComponent = <Header titleText="Not Found" />;

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  feature,
  isAccessible,
  currentState,
  ...rest
}) => {
  const { isLoggedIn } = currentState;
  const routeComponent = (props: any) =>
    isLoggedIn ? (
      React.createElement(component, { ...props, isAccessible, feature, currentState })
    ) : (
      <Redirect to={ROUTES.DEFAULT_PUBLIC_PATH} />
    );

  return isAccessible ? <Route {...rest} render={routeComponent} /> : emptyComponent;
};

export default PrivateRoute;
