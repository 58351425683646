import React from 'react';
import { connect } from 'react-redux';
import { IonButtons, IonButton, IonContent, IonPage, IonItem, IonLabel, IonIcon } from '@ionic/react';
import { removeCircleOutline } from 'ionicons/icons';

import { theme } from '$gbusiness/services';
import { THEMES, COLORS } from '$gbusiness/enums';
import { Text } from '$gcomponents/primitives';
import { dialog } from '$gcomponents/reusables';
import { screen } from '$fcomponents/hoc';
import { COLOR, SPACE, Wrapper, Div } from '$gstyles';

import Header from '$components/header';
import { clog } from '$gbusiness/helpers/util';

interface OwnProps {}

const HomeScreen: React.FC<OwnProps> = () => {
  const { DEFAULT, AUTUMN, NIGHT, NEON, DARK } = THEMES;

  return (
    <IonPage>
      <Header />

      <IonContent>
        <Wrapper>
          <h2>
            <Text k="SCREEN.HOME.TITLE" />
          </h2>
          <Text k="SCREEN.HOME.SUBTITLE" />
          <IonItem>
            <IonButton
              onClick={() =>
                dialog.alert({
                  title: 'MESSAGE.SUCCESS',
                  message: 'TEST',
                  subtitle: 'MESSAGE.DELETE_WARNING',
                  key: { name: 'Supercalifrgerlisticexplialidocous' },
                })
              }>
              Show Alert
            </IonButton>
          </IonItem>
          <IonItem>
            <IonButton
              color="secondary"
              onClick={() =>
                dialog.confirm({
                  title: 'MESSAGE.WAIT',
                  message: 'MESSAGE.DELETE.WARNING',
                  onPress: () => {
                    clog('CONFIRM PRESSED!');
                  },
                })
              }>
              Show Confirm
            </IonButton>
          </IonItem>
          <IonItem>
            <IonIcon icon={removeCircleOutline} slot="start" />
            <IonLabel>Use Dark Theme</IonLabel>
          </IonItem>
          <IonItem>
            <IonButtons>
              <IonButton color={COLORS.PRIMARY} onClick={() => theme.switchTheme(DEFAULT)}>
                Default
              </IonButton>
              <IonButton color={COLORS.SECONDARY} onClick={() => theme.switchTheme(AUTUMN)}>
                Autumn
              </IonButton>
              <IonButton color={COLORS.TERTIARY} onClick={() => theme.switchTheme(NIGHT)}>
                Night
              </IonButton>
              <IonButton color={COLORS.MEDIUM} onClick={() => theme.switchTheme(NEON)}>
                Neon
              </IonButton>
              <IonButton color={COLORS.DARK} onClick={() => theme.switchTheme(DARK)}>
                Dark
              </IonButton>
            </IonButtons>
          </IonItem>
          <Div
            margin={SPACE.LARGE}
            border={`1px solid ${COLOR.SECONDARY}`}
            background={COLOR.LIGHT}
            color={COLOR.PRIMARY}
            padding={SPACE.MEDIUM}>
            Testing Div Component Here
          </Div>
        </Wrapper>
      </IonContent>
    </IonPage>
  );
};

const connected = connect(null, null);

export default connected(screen(HomeScreen));
