import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .title {
    .MuiSvgIcon-root {
      font-size: ${FONT.XLARGE};
      margin-right: ${SPACE.LARGE};
    }
    color: #63995b;
    font-size: ${FONT.LARGE};
    font-weight: ${WEIGHT.SEMI_BOLD};
  }

  .subtitle {
    margin-top: ${SPACE.XLARGE};
    font-size: ${FONT.LARGE};
    font-weight: ${WEIGHT.SEMI_BOLD};
  }

  .cart-item {
    .item-text {
      margin-left: ${SPACE.LARGE};
    }
  }

  .summary-wrapper {
    width: 300px;
    .box {
      padding: ${SPACE.LARGE};
    }
  }
`;
