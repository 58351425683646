import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
  IonHeader,
  IonToolbar,
  IonImg,
  IonFooter,
} from '@ionic/react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { configs } from '$configs';
import { ROUTES } from '$business/enums';
import intl, { Text } from '$intl';

import { Wrapper } from './styles';
import { AppVersion } from '$gcomponents/reusables';
import { Div } from '$gstyles';
import DepartmentModel from '$fbusiness/models/department';
import HomeIcon from '@mui/icons-material/Home';
import { accessString } from '$gbusiness/helpers/util';

interface MenuProps extends RouteComponentProps {
  isLoggedIn: boolean;
  isOrderRoute: boolean;
  departments: Array<DepartmentModel>;
  currentRoute: string;
  onLogout: Function;
  logo?: string;
}

const Menu: React.FC<MenuProps> = React.memo(
  ({ departments, isOrderRoute, currentRoute, logo, isLoggedIn, onLogout, ...rest }) => {
    return (
      <Wrapper
        contentId="main"
        menuId="start"
        disabled={!isLoggedIn}
        isProd={process.env.REACT_APP_ENV === 'prod'}
        {...rest}>
        {configs.display.layout !== 'FIXED_HEADER' && (
          <IonHeader>
            <IonToolbar>
              <IonImg class="logo" src={logo} />
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent class="outer-content">
          {!isOrderRoute && isLoggedIn && (
            <IonList>
              <IonMenuToggle autoHide={false} className="dept-item">
                <IonItem
                  className={`${ROUTES.PATH.MENU}/0/0` === currentRoute ? 'selected pointer' : 'pointer'}
                  routerLink={`${ROUTES.PATH.MENU}/0/0`}
                  routerDirection="none"
                  lines="none"
                  detail={false}>
                  <HomeIcon />
                  <IonLabel>{intl('MENU.BACK_TO_ORDER')}</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <br />
              {ROUTES.ACCOUNT_LIST.map((appPage, index) => {
                if (!appPage.title) return null;
                return (
                  <IonMenuToggle
                    key={index}
                    autoHide={false}
                    className={accessString(appPage.accessLevel, appPage.feature)}>
                    <IonItem
                      className={currentRoute === appPage.path ? 'selected pointer' : 'pointer'}
                      routerLink={`${appPage.path}${appPage.defaultId ? '/' + appPage.defaultId : ''}`}
                      routerDirection="none"
                      lines="none"
                      detail={false}>
                      {appPage.icon && <appPage.icon />}
                      <IonLabel>
                        <Text k={appPage?.title} />
                      </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              })}
              <IonItem lines="none" class="pointer" onClick={() => onLogout()} detail={false}>
                <ExitToAppIcon />
                <IonLabel>
                  <Text k="MENU.LOG_OUT" />
                </IonLabel>
              </IonItem>
            </IonList>
          )}
          {isOrderRoute && (
            <IonList>
              <IonMenuToggle autoHide={false} className="dept-item">
                <IonItem
                  className={`${ROUTES.PATH.MENU}/0/0` === currentRoute ? 'selected pointer' : 'pointer'}
                  routerLink={`${ROUTES.PATH.MENU}/0/0`}
                  routerDirection="none"
                  lines="none"
                  detail={false}>
                  <IonLabel>{intl('SCREEN.ITEMS.TITLE')}</IonLabel>
                </IonItem>
              </IonMenuToggle>
              {departments.map((dept, index) => {
                const categories = (dept?.categories || []).map((cat, i) => {
                  return (
                    <IonMenuToggle key={i} autoHide={false} className="cat-item">
                      <IonItem
                        className={
                          `${ROUTES.PATH.MENU}/${dept.id}/${cat.id}` === currentRoute
                            ? 'selected pointer'
                            : 'pointer'
                        }
                        routerLink={`${ROUTES.PATH.MENU}/${dept.id}/${cat.id}`}
                        routerDirection="none"
                        lines="none"
                        detail={false}>
                        <IonLabel>{cat.name}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
                });
                return (
                  <div key={index}>
                    <IonMenuToggle autoHide={false} className="dept-item">
                      <IonItem
                        className={
                          `${ROUTES.PATH.MENU}/${dept.id}/0` === currentRoute ? 'selected pointer' : 'pointer'
                        }
                        routerLink={`${ROUTES.PATH.MENU}/${dept.id}/0`}
                        routerDirection="none"
                        lines="none"
                        detail={false}>
                        <IonLabel>{dept.name}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    {categories}
                  </div>
                );
              })}
              <br></br>
              {ROUTES.MENU_LIST.map((appPage, index) => {
                if (!appPage.title) return null;
                return (
                  <IonMenuToggle key={index} autoHide={false} className={accessString(appPage.accessLevel)}>
                    <IonItem
                      className={currentRoute === appPage.path ? 'selected pointer' : 'pointer'}
                      routerLink={`${appPage.path}${appPage.defaultId ? '/' + appPage.defaultId : ''}`}
                      routerDirection="none"
                      lines="none"
                      detail={false}>
                      {appPage.icon && <appPage.icon />}
                      <IonLabel>
                        <Text k={appPage?.title} />
                      </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              })}
              <IonItem lines="none" class="pointer" onClick={() => onLogout()} detail={false}>
                <ExitToAppIcon />
                <IonLabel>
                  <Text k="MENU.LOG_OUT" />
                </IonLabel>
              </IonItem>
            </IonList>
          )}
        </IonContent>
        <IonFooter>
          <Div height="32px">
            <AppVersion oneLine />
          </Div>
        </IonFooter>
      </Wrapper>
    );
  },
  (pp, np) =>
    pp.logo === np.logo &&
    pp.currentRoute === np.currentRoute &&
    pp.isLoggedIn === np.isLoggedIn &&
    pp.departments.length === np.departments.length,
);

export default withRouter(Menu);
