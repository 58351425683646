import React from 'react';
import { useSelector } from 'react-redux';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  IonHeader,
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonMenuToggle,
  IonButton,
  IonSearchbar,
} from '@ionic/react';

import { configs } from '$configs';
import intl from '$intl';
import UserModel from '$gbusiness/models/user';

import { Toolbar } from './styles';
import { Badge } from '@mui/material';
import { AppModel } from '$business/redux';
interface HeaderProps {
  title?: string;
  titleParam?: object;
  titleText?: string;
  rightButton?: React.ReactNode;
  excludeHeader?: boolean;
  disableBack?: boolean;
  query?: string;
  onChangeQuery?: Function;
  user?: UserModel;
}

const Header: React.FC<HeaderProps> = ({
  title,
  titleText,
  titleParam = {},
  disableBack = false,
  excludeHeader = false,
  query = '',
  onChangeQuery,
}) => {
  const cartLength = useSelector((state: AppModel) => state.localStorage?.cart.products?.length || 0);
  if (configs.display.layout === 'FIXED_HEADER') return null;

  const derivedTitle = intl(title, titleParam) || titleText || '';
  const titleComponent = derivedTitle || intl('COMMON.APP_TITLE');
  const searchComponent = (
    <IonSearchbar
      value={query}
      placeholder={intl('INPUT.PLACEHOLDER.PRODUCT_SEARCH')}
      onIonChange={(e) => onChangeQuery && onChangeQuery(e.detail.value)}
    />
  );

  const el = (
    <Toolbar color={configs.theme.headerColor || undefined}>
      {!disableBack && (
        <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
      )}
      <IonButtons slot="start">
        <IonMenuButton />
      </IonButtons>
      <IonTitle>{onChangeQuery ? searchComponent : titleComponent}</IonTitle>
      <IonButtons slot="end">
        <IonMenuToggle autoHide menu="end">
          <IonButton className="receipt">
            <Badge badgeContent={cartLength} color="error">
              <ShoppingCartIcon />
            </Badge>
          </IonButton>
        </IonMenuToggle>
      </IonButtons>
    </Toolbar>
  );

  return excludeHeader ? <>{el}</> : <IonHeader>{el}</IonHeader>;
};

export default Header;
